import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Motoko variants are a cool language feature that lets you tag data with a custom label. In the base form, the label is the entire information that you are storing, similar to an enum. For example,`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Name`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    #tag`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` name `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Name`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` #tag`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`As you can see, our variable `}<inlineCode parentName="p">{`name`}</inlineCode>{` is of type `}<inlineCode parentName="p">{`Name`}</inlineCode>{`, which is a variant with a single tag `}<inlineCode parentName="p">{`#tag`}</inlineCode>{`. We can also add a Type to the tag, which looks like this:`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Name`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    #tag `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` name `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Name`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` #tag `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Hello"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`The tag is separated from the typed value with a simple space. This is not useful yet in this format, since we only are using a single variant. However, this can be expanded to include multiple tags, like this:`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`PetName`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    #cat `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    #dog `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` name `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`PetName`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` #cat `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Sardine"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Now we are able to differentiate cats and dogs in the same type. This is useful for storing data that can be one of multiple types. For example, if we wanted to store a list of pets, we could do this:`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`PetName`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    #cat `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    #dog `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` petList`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`PetName`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`#cat `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Sardine"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` #dog `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Rex"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`This can also be a way to store mixed types. Text is a useful shorthand, but you could also tag your variants as `}<inlineCode parentName="p">{`#text "Some text"`}</inlineCode>{`, `}<inlineCode parentName="p">{`#nat 0`}</inlineCode>{`, `}<inlineCode parentName="p">{`#int 0`}</inlineCode>{`, `}<inlineCode parentName="p">{`#bool true`}</inlineCode>{`, etc.`}</p>
    <h2 {...{
      "id": "using-variants-in-functions"
    }}><a parentName="h2" {...{
        "href": "#using-variants-in-functions"
      }}>{`Using Variants in Functions`}</a></h2>
    <p>{`To iterate through your list and make choices depending on the variant, you can use a `}<inlineCode parentName="p">{`switch`}</inlineCode>{` statement. Here's an example using an array of data that are either Text or Nat types, and returning all the Text values:`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`import `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Buffer`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Buffer"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

actor `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`TextOrNat`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    #text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    #nat`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  var list`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`TextOrNat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`#text `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"some text"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` #nat `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` #text `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"0"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  
  public query func `}<span parentName="code" {...{
          "className": "token function"
        }}>{`getText`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` textBuf `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Buffer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`fromArray`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`for`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`item `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`in`}</span>{` list`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`vals`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`switch`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`item`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`case`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`#text t`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          textBuf`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`t`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        `}<span parentName="code" {...{
          "className": "token function"
        }}>{`case`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`#nat n`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
          `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// skip`}</span>{`
        `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Buffer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`toArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`textBuf`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`By using the `}<inlineCode parentName="p">{`switch`}</inlineCode>{` statement on the `}<inlineCode parentName="p">{`item`}</inlineCode>{`, we can set our cases to cover all of the variant tags in our `}<inlineCode parentName="p">{`TextOrNat`}</inlineCode>{` type. We handle the tag and then specify a variable name to use for the value. In this case, we are only interested in the `}<inlineCode parentName="p">{`#text`}</inlineCode>{` tag, so we add the value to our `}<inlineCode parentName="p">{`textBuf`}</inlineCode>{` buffer. If we wanted to handle the `}<inlineCode parentName="p">{`#nat`}</inlineCode>{` tag, we could add a case for that as well.`}</p>
    <p>{`You can see this little example on the Motoko playground at `}<a parentName="p" {...{
        "href": "https://m7sm4-2iaaa-aaaab-qabra-cai.raw.ic0.app/?tag=2689482111"
      }}>{`https://m7sm4-2iaaa-aaaab-qabra-cai.raw.ic0.app/?tag=2689482111`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      